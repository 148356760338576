import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Dustin Robison | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my profile website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Dustin Robison',
  subtitle: `I'm a Fullstack Developer`,
  cta: 'Show me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I am living my dream of becoming a Software Developer and I enjoy making creative ideas come to life. I wanted to become a software developer since I first started hacking away on a DOS machine in the early 1990's with my first programming book. And I was certain of it once I experienced the magic of virtual worlds.`,
  paragraphTwo: `These days I am always looking for the best tool for the job.  Today I build sites with Gatsby and GraphQL (and it is AMAZING) but I still retain the experience and knowledge from a long list of past tools and languages.`,
  paragraphThree: `My first passion is being a husband and father.  My next lifelong passion is creating.`,
  // resume: 'https://www.resumemaker.online/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'dronequote.png',
    title: 'Dronequote',
    info: `Dronequote.net is a wonderful company that I joined in 2020 as CTO. We use drones to survey residential roofing and capture high resolution images of the exterior.  With this information we can get solar quotes from multiple installers and roofing needs met with ease.`,
    info2: `Working with Dronequote has been a wonderful experience.  I had the opportunity to build the full stack of a hybrid sales system with catalog and CRM that is being used to automate much of the sales process. The scope of this project is quite large as it contains everything from customers, installers, sellers, pilots, parts, warranties, documents, proposals, etc. the list goes on.  `,
    url: `https://dronequote.net`,
    // repo: 'https://github.com/DustinRobison/StoryPointPoker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'eml.png',
    title: 'Escondido Mortgage Lender',
    info: `I build this website for my family to market their small business, and I wanted it to be the best.  First, it has optimal performance because I used pre-rendered html with Gatsby and Netlify global CDN for fast delivery.  Next it needs to be user customizable to generate high SEO scores so I used Netlify CMS with Git gateway.  Lastly, the site needed to generate business by allowing end users to enter their data easily and securely online and submit it to my families business. I am pleased to say that Google Lighthouse reports excellent performance and SEO scores and my family is very satisfied with the website.`,
    info2: `I really enjoyed creating content and interacting with clients in a constant feedback loop. During this process I was constantly working with marketing clients and had a very positive experience and a great solution.`,
    url: `https://escondidomortgagelender.com`,
    // repo: 'https://github.com/DustinRobison/StoryPointPoker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'storypointpoker.png',
    title: 'StoryPointPoker.com',
    info: 'A favorite little JAMSTACK side project!',
    info2: `My team was having trouble with some of the free Agile planning poker tools available while working remote so I decided one night to hack together storypointpoker.com. It is currently made with Create React App but it is actually generating some traffic so I plan to upgrade it to Static content or SSR in order to get better SEO.`,
    url: `https://storypointpoker.com`,
    repo: 'https://github.com/DustinRobison/StoryPointPoker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'viasat_map.png',
    title: 'Viasat ISP - Aviation Analytics Tools',
    info: `I have been working at Viasat for over 5 years collecting and delivering performance based analytical data for the Viasat Broadband Satellite Internet service on aircraft.  These metrics are then available to customers and are used to show the quality of Viasat's service.`,
    info2: `This has been a wonderful challenge over the years because this task is very broad and interfaces with various technologies and massive technical challenges at a large scale. Unfortunately this project is private to customer so I cannot share it here.`,
    // url: '',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'dustinrobi@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/RobisonDustin',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/dustin-robison-2b13a227/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/DustinRobison',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
